import React, { useState, useEffect, useRef } from 'react';
import WebAppLayout from '../../components/layout/webapp/WebAppLayout';
import { useNavigate } from 'react-router-dom';
const Home = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/it-development-support-services');
    };
    const cardData = [
        { icon: 'fab fa-shopify', title1: 'Shopify', title2: '& Tech' },
        { icon: 'bi-brush', title1: 'Graphics', title2: '& Design' },
        { icon: 'bi-phone', title1: 'Digital', title2: 'Marketing' },
        { icon: 'bi-translate', title1: 'Writing', title2: '& Translation' },
        { icon: 'bi-film', title1: 'Video', title2: '& Animation' },
        { icon: 'bi-robot', title1: 'AI', title2: 'Services' },
        { icon: 'bi-music-note-beamed', title1: 'Music', title2: '& Audio' },
        { icon: 'bi-briefcase', title1: 'Business', title2: '' },
        { icon: 'bi-chat-square-text', title1: 'Consulting', title2: '' }
    ];
    const innerContainerRef = useRef(null);
    useEffect(() => {
        const innerContainer = innerContainerRef.current;

        const handleAnimation = () => {
            const scrollWidth = innerContainer.scrollWidth;
            innerContainer.animate(
                [
                    { transform: 'translateX(0)' },
                    { transform: `translateX(-${scrollWidth / 2}px)` }
                ],
                {
                    duration: 20000, // 20 seconds
                    iterations: Infinity,
                    easing: 'linear'
                }
            );
        };

        handleAnimation();
    }, []);

    return (
        <WebAppLayout>
            <div className={`box-banner-right-home6 bg-primary-custom rounded-custom container mt-lg-6 mt-0`}>
                <div className="row">
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 ">
                      
                    </div>
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5">
                        <h1 className="text-white text-start mt-6">Join the right freelance service, right away</h1>
                        <h6 className="text-white text-start mt-3">Forget the old rules. You can have the best freelancers for your project, right now, right here.</h6>
                         <div className="d-flex justify-content-start mt-3" style={{ flexWrap: 'nowrap' }}>
      <button className="btn btn-dark btn-sm me-2 mb-5" type="button" onClick={handleClick}>
          Get freelance service
      </button>
      <button className="btn btn-outline-light btn-sm mb-5" type="button">
          Join freelancer team
      </button>
  </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-6">
                        <img
                            src="assets/home.png"
                            alt="Freelancing Team"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                {/* Card Container */}
                <div className="card-container" style={{ display: 'flex', overflow: 'hidden', height: '200px', position: 'relative' }}>
                    <div ref={innerContainerRef} className="inner-container" style={{ display: 'flex' }}>
                        {/* Map the cards twice to achieve the continuous scroll */}
                        {cardData.concat(cardData).map((card, index) => (
                            <div key={index} style={{ marginRight: '20px' }}> {/* Add margin here */}
                                <div
                                    className="card overflow-hidden"
                                    style={{
                                        width: "9rem",
                                        height: "9rem",
                                        borderRadius: "20px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                                    }}
                                >
                                    <div className="card-body d-flex flex-column justify-content-start">
                                        <div className="icon mb-3 d-flex align-items-start justify-content-start">
                                            <i className={`${card.icon} fs-4`}></i>
                                        </div>
                                        <h6 className="text-dark">{card.title1}</h6>
                                        <h6 className="text-dark">{card.title2}</h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </WebAppLayout>
    );
};

export default Home;
