// src/components/layout/dashboard/DashboardLayout.js
import React from 'react';
import DashboardHeader from './DashboardHeader';
import DashboardFooter from './DashboardFooter';

const DashboardLayout = ({ children }) => {
    return (

        <div className="dashboard-layout">
            <DashboardHeader />
            <div className="dashboard-content">
                {children}
            </div>
            <DashboardFooter />
        </div>
    );
};

export default DashboardLayout;