import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import WebAppLayout from '../../components/layout/webapp/WebAppLayout';

const IT_Development_support_services = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);
    return (
        <WebAppLayout>  
            <div className="">
                <div className="container">
                    <div className="row mt-7">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-12">
                                    <div className="mb-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h6>Service Form</h6>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="First Name">
                                                First Name
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="First Name"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Last Name">
                                                Last Name
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Email">
                                                Email
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Email"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Phone Number">
                                                Phone Number
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Organization Name">
                                                Organization Name
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Organization Name"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="numberOfEmployees">
                                                Number Of Employees
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <select
                                                    className="form-select form-select-sm"
                                                    id="numberOfEmployees"
                                                    aria-label="Number Of Employees"
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value="" disabled>Select Number Of Employees</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="11-50">11-50</option>
                                                    <option value="51-200">51-200</option>
                                                    <option value="201-500">201-500</option>
                                                    <option value="501-1000">501-1000</option>
                                                    <option value="1000+">1000+</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Designation">
                                                Designation
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <select
                                                    className="form-select form-select-sm"
                                                    aria-label="Designation"
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value="" disabled>Select Designation</option>
                                                    <option value="CEO/Founder/GM">CEO/Founder/GM</option>
                                                    <option value="CFO">CFO</option>
                                                    <option value="CTO/CIO">CTO/CIO</option>
                                                    <option value="Director Head of Department">Director Head of Department</option>
                                                    <option value="IT/System Manager">IT/System Manager</option>
                                                    <option value="Manager (sales/marketing/customer)">Manager (sales/marketing/customer)</option>
                                                    <option value="Developer Analyst">Developer Analyst</option>
                                                    <option value="Student/Freelancer/Just looking">Student/Freelancer/Just looking</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Business Domain">
                                                Business Domain
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <select
                                                    className="form-select form-select-sm"
                                                    aria-label="Business Domain"
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value="" disabled>Select Business Domain</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Distribution">Distribution</option>
                                                    <option value="Services">Services</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Healthcare">Healthcare</option>
                                                    <option value="Education">Education</option>
                                                    <option value="Agriculture">Agriculture</option>
                                                    <option value="Non Profit">Non Profit</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Country">
                                                Country
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <select
                                                    className="form-select form-select-sm"
                                                    aria-label="Country"
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value="" disabled>Select Country</option>
                                                    <option value="India">India</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Service Type">
                                                Service Type
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <select
                                                    className="form-select form-select-sm"
                                                    aria-label="Service Type"
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value="" disabled>Select Service Type</option>
                                                    <option value="Development">Development</option>
                                                    <option value="support">support</option>
                                                    <option value="products ">products</option>
                                                </select>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Services">
                                                Services
                                            </label>
                                            {loading ? (
                                                <Skeleton height={35} />
                                            ) : (
                                                <div style={{ maxHeight: '100px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                                                    <ul className="list-unstyled">
                                                        <li><input type="checkbox" id="service1" /> <label htmlFor="service1">ABAP</label></li>
                                                        <li><input type="checkbox" id="service2" /> <label htmlFor="service2">Consulting</label></li>
                                                        <li><input type="checkbox" id="service3" /> <label htmlFor="service3">Web Designing</label></li>
                                                        <li><input type="checkbox" id="service4" /> <label htmlFor="service4">Web Development</label></li>
                                                        <li><input type="checkbox" id="service5" /> <label htmlFor="service5">SAP</label></li>
                                                        <li><input type="checkbox" id="service6" /> <label htmlFor="service6">Customization</label></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="Description">
                                                Description
                                            </label>
                                            {loading ? (
                                                <Skeleton height={75} />
                                            ) : (
                                                <textarea
                                                    className="form-control form-control-sm"
                                                    rows="5"
                                                    placeholder="Description"
                                                    style={{ width: '100%' }}
                                                ></textarea>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row justify-content-end">
                                    {loading ? (
                                        <>
                                            <Skeleton height={35} width={80} className="me-md-2 mt-1 mb-1" />
                                            <Skeleton height={35} width={80} className="mt-1 mb-1" />
                                        </>
                                    ) : (
                                        <>
                                            <button className="  btn btn-outline-secondary btn-sm mt-1 mb-1 me-md-2" type="button">
                                                Discard
                                            </button>
                                            <button className="btn btn-dark btn-sm mt-1 mb-1" type="button">
                                                Next
                                            </button>
                                        </>
                                    )}
                                </div>

                            </form>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="mb-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <div className="card bg-100 shadow-none border">
                                                <div className="row gx-0 flex-column flex-sm-row align-items-center justify-content-center">

                                                    <div className="col-12 col-sm-2 text-center text-sm-start mb-2 mb-sm-0">
                                                        <span
                                                            className="fas fa-headset text-dark fs-5"
                                                            style={{
                                                                width: '80px',
                                                                height: '80px',
                                                                margin: '0 auto',
                                                                padding: '15px'
                                                            }}
                                                        ></span>
                                                    </div>

                                                    <div className="col-12 col-sm-6 text-center text-sm-start mb-2 mb-sm-0">
                                                        <h6 className="text-dark fs--1">Welcome to</h6>
                                                        <h4 className="text-primary fw-bold">
                                                            <span className="text-primary-custom fw-medium">Support</span>
                                                            <span className="text-dark fw-medium ms-2">Desk</span>
                                                        </h4>
                                                    </div>

                                                    <div className="col-12 col-sm-4 text-center mb-2 mt-1">
                                                        <button className="btn btn-dark btn-sm" type="button">
                                                            Join now
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>




                            <div className="row mt-5">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mt-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h5>Need a quick Solution?</h5>
                                        )}
                                    </div>
                                    <div className="">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h6>Click here to register for a weekly awaitware consultant webinar and live Q&A.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mt-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h5>Looking for a basic implementation?</h5>
                                        )}
                                    </div>
                                    <div className="">
                                        {loading ? (
                                            <Skeleton height={70} />
                                        ) : (
                                            <h6>If you have limited users and need help with self-implementation, register for our small business success pack. Currently available only for Indian customers.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mt-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h5>Here for support?</h5>
                                        )}
                                    </div>
                                    <div className="">
                                        {loading ? (
                                            <Skeleton height={70} />
                                        ) : (
                                            <h6>If you choose service raise a ticket on our support portal.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mt-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h5>Interested in awaitware partnership?</h5>
                                        )}
                                    </div>
                                    <div className="">
                                        {loading ? (
                                            <Skeleton height={70} />
                                        ) : (
                                            <h6>Register for our partner program to get assistance, training and sales benefits for promoting and implementing awaitware products.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-12 col-sm-12">
                                    <div className="mt-3">
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <h5>Connect with our consultants!</h5>
                                        )}
                                    </div>
                                    <div className="">
                                        {loading ? (
                                            <Skeleton height={70} />
                                        ) : (
                                            <h6>Fill out this form and someone from our team will be in touch.</h6>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WebAppLayout>
    );
};

export default IT_Development_support_services;
