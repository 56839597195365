// src/views/webapp/Home.js
import React from 'react';
import WebAppLayout from '../../components/layout/webapp/WebAppLayout';

const Solutions = () => {

    return (
        <WebAppLayout>
            <section className="bg-primary-custom box-banner-right-home6">
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6 col-sm-3 mt-5">
                            <h1 className="text-primary-custom text-start mt-2">The most agile freelancing team</h1>
                        </div>
                    </div>
                </div>
            </section>
        </WebAppLayout>
    );
};

export default Solutions;