// src/components/layout/dashboard/DashboardHeader.js
import React from 'react';

const DashboardHeader = () => {
    return (
        <header>
            <h1>Dashboard Header</h1>
        </header>
    );
};

export default DashboardHeader;