import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Header = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/contact-us');
    };
    return (
        <nav
            className="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark bg-white"
            data-navbar-darken-on-scroll="data-navbar-darken-on-scroll"
            style={{ borderBottom: "0.1em solid rgba(0, 0, 0, 0.2)" }}
        >
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <span className="text-dark dark__text-white">
                        <span className="text-primary-custom">await</span>
                        <span className="text-dark">ware</span>
                    </span>
                </Link>
                <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarStandard"
                    aria-controls="navbarStandard"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse scrollbar " id="navbarStandard">

                    <ul
                        className="navbar-nav"
                        data-top-nav-dropdowns="data-top-nav-dropdowns"
                    >
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="Services"
                            >
                                Services
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="Services"
                            >
                                <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/it-development-support-services"
                                    >
                                        IT Development | support
                                    </Link>

                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="Solutions"
                            >
                                Solutions
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="Solutions"
                            >
                                <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/solutions"
                                    >
                                        Solutions Explained
                                    </Link>

                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle text-dark"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                id="About"
                            >
                                About Us
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-card border-0 mt-0"
                                aria-labelledby="About"
                            >
                                <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/about-us"
                                    >
                                        awaitware About Explained
                                    </Link>
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/blog"
                                    >
                                        Blog
                                    </Link>
                                    <Link
                                        className="dropdown-item link-600 fw-medium"
                                        to="/testimonials"
                                    >
                                        Testimonials
                                    </Link>
                                </div>
                            </div>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <Link className="nav-link text-dark" to="/pricing" >*/}
                        {/*        <span*/}
                        {/*            className="d-lg-inline-block"*/}
                        {/*            data-bs-toggle="tooltip"*/}
                        {/*            data-bs-placement="bottom"*/}
                        {/*            title="Dashboard"*/}
                        {/*        >*/}
                        {/*        </span>*/}
                        {/*        Pricing*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <button className="btn btn-dark btn-sm mt-1" type="button" onClick={handleClick}>
                                Contact Us
                            </button>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    );
}
export default Header;
