import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import WebAppLayout from '../../components/layout/webapp/WebAppLayout';
const Contact = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating data fetching
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <WebAppLayout>
            <div className="">
                <div className="container mt-7">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="mb-3">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h6>Contact Form</h6>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h2 className="text-dark dark__text-white">
                                        <span className="text-primary-custom">Get in</span>
                                        <span className="text-dark"> touch</span>
                                    </h2>

                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h6>Get help with awaitware products and services</h6>
                                )}
                            </div>

                            <div className="mt-5">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h5>Need a quick Solution ?</h5>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h6>Click here to register for a weekly awaitware consultant webinar and live Q&A.</h6>
                                )}
                            </div>
                            <div className="mt-5">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h5>Looking for a basic implementation ?</h5>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={70} />
                                ) : (
                                    <h6>
                                        If you have limited users and need help with self-implementation, register for our small business success pack. Currently available only for Indian customers.
                                    </h6>
                                )}
                            </div>
                            <div className="mt-5">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h5>Here for support ?</h5>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={70} />
                                ) : (
                                    <h6>
                                        If you choose service raise a ticket on our support portal.
                                    </h6>
                                )}
                            </div>
                            <div className="mt-5">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h5>Interested in awaitware partnership ?</h5>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={70} />
                                ) : (
                                    <h6>
                                        Register for our partner program to get assistance, training, and sales benefits for promoting and implementing awaitware products.
                                    </h6>
                                )}
                            </div>
                            <div className="mt-5">
                                {loading ? (
                                    <Skeleton height={35} />
                                ) : (
                                    <h5>Connect with our consultants!</h5>
                                )}
                            </div>
                            <div className="">
                                {loading ? (
                                    <Skeleton height={70} />
                                ) : (
                                    <h6>
                                        Fill out this form and someone from our team will be in touch.
                                    </h6>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="FirstName">First Name</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <input
                                                    {...register('FirstName', { required: 'First Name is required' })}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="First Name"
                                                />
                                                {errors.FirstName && <span className="text-danger small">{errors.FirstName.message}</span>}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="LastName">Last Name</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <input
                                                    {...register('LastName', { required: 'Last Name is required' })}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Last Name"
                                                />
                                                {errors.LastName && <span className="text-danger small">{errors.LastName.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="Email">Email</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <input
                                                    {...register('Email', {
                                                        required: 'Email is required',
                                                        pattern: {
                                                            value: /^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Email"
                                                />
                                                {errors.Email && <span className="text-danger small">{errors.Email.message}</span>}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="PhoneNumber">Phone Number</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <input
                                                    {...register('PhoneNumber', {
                                                        required: 'Phone Number is required',
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Invalid phone number'
                                                        }
                                                    })}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                />
                                                {errors.PhoneNumber && <span className="text-danger small">{errors.PhoneNumber.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label className="form-label" htmlFor="OrganizationName">Organization Name</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <input
                                                    {...register('OrganizationName', { required: 'Organization Name is required' })}
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    placeholder="Organization Name"
                                                />
                                                {errors.OrganizationName && <span className="text-danger small">{errors.OrganizationName.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="numberOfEmployees">Number Of Employees</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <select
                                                    {...register('numberOfEmployees', { required: 'Number of Employees is required' })}
                                                    className="form-select form-select-sm"
                                                    id="numberOfEmployees"
                                                    aria-label="Number Of Employees"
                                                    defaultValue=""
                                                >
                                                    <option value="" disabled>Select Number Of Employees</option>
                                                    <option value="1-10">1-10</option>
                                                    <option value="11-50">11-50</option>
                                                    <option value="51-200">51-200</option>
                                                    <option value="201-500">201-500</option>
                                                    <option value="501-1000">501-1000</option>
                                                    <option value="1000+">1000+</option>
                                                </select>
                                                {errors.numberOfEmployees && <span className="text-danger small">{errors.numberOfEmployees.message}</span>}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="Designation">Designation</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <select
                                                    {...register('Designation', { required: 'Designation is required' })}
                                                    className="form-select form-select-sm"
                                                    id="Designation"
                                                    aria-label="Designation"
                                                    defaultValue=""
                                                >
                                                    <option value="" disabled>Select Designation</option>
                                                    <option value="CEO/Founder/GM">CEO/Founder/GM</option>
                                                    <option value="CFO">CFO</option>
                                                    <option value="CTO/CIO">CTO/CIO</option>
                                                    <option value="Director Head of Department">Director Head of Department</option>
                                                    <option value="IT/System Manager">IT/System Manager</option>
                                                    <option value="Manager (sales/marketing/customer)">Manager (sales/marketing/customer)</option>
                                                    <option value="Developer Analyst">Developer Analyst</option>
                                                    <option value="Student/Freelancer/Just looking">Student/Freelancer/Just looking</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.Designation && <span className="text-danger small">{errors.Designation.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="BusinessDomain">Business Domain</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <select
                                                    {...register('BusinessDomain', { required: 'Business Domain is required' })}
                                                    className="form-select form-select-sm"
                                                    id="BusinessDomain"
                                                    aria-label="Business Domain"
                                                    defaultValue=""
                                                >
                                                    <option value="" disabled>Select Business Domain</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Distribution">Distribution</option>
                                                    <option value="Services">Services</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Healthcare">Healthcare</option>
                                                    <option value="Education">Education</option>
                                                    <option value="Agriculture">Agriculture</option>
                                                    <option value="Non Profit">Non Profit</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                                {errors.BusinessDomain && <span className="text-danger small">{errors.BusinessDomain.message}</span>}
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12 mb-3">
                                        <label className="form-label" htmlFor="Country">Country</label>
                                        {loading ? (
                                            <Skeleton height={35} />
                                        ) : (
                                            <>
                                                <select
                                                    {...register('Country', { required: 'Country is required' })}
                                                    className="form-select form-select-sm"
                                                    id="Country"
                                                    aria-label="Country"
                                                    defaultValue=""
                                                >
                                                    <option value="" disabled>Select Country</option>
                                                    <option value="India">India</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.Country && <span className="text-danger small">{errors.Country.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label className="form-label" htmlFor="Message">Message</label>
                                        {loading ? (
                                            <Skeleton height={150} />
                                        ) : (
                                            <>
                                                <textarea
                                                    {...register('Message', { required: 'Message is required' })}
                                                    className="form-control"
                                                    id="Message"
                                                    rows="4"
                                                />
                                                {errors.Message && <span className="text-danger small">{errors.Message.message}</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-md-row justify-content-end">
                                    {loading ? (
                                        <>
                                            <Skeleton height={35} width={80} className="me-md-2 mt-1 mb-1" />
                                            <Skeleton height={35} width={80} className="mt-1 mb-1" />
                                        </>
                                    ) : (
                                        <>
                                            <button className="btn btn-outline-secondary btn-sm mt-1 mb-1 me-md-2" type="button">
                                                Discard
                                            </button>
                                            <button className="btn btn-dark btn-sm mt-1 mb-1" type="submit">
                                                Save
                                            </button>
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </WebAppLayout>
    );
};

export default Contact;
