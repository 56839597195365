import React from 'react';
import Header from './WebAppHeader';
import Footer from './WebAppFooter';

const Layout = ({ children }) => {
    return (
        <div>
            <main>
                <Header />
                <section className="py-0 overflow-hidden light mt-4">
                    {children}
                </section>
                <Footer />
            </main>
        </div>
    );
}
export default Layout;
