// src/components/layout/dashboard/DashboardFooter.js
import React from 'react';

const DashboardFooter = () => {
    return (
        <footer>
            <p>Dashboard Footer</p>
            
        </footer>
    );
};

export default DashboardFooter;