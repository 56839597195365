// src/routes/WebAppRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../views/webapp/Home';
import About from '../views/webapp/About';
import Contact from '../views/webapp/Contact';
import IT_Development_support_services from '../views/webapp/IT_Development_support_services';
import Solutions from '../views/webapp/Solutions';


const WebAppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/it-development-support-services" element={<IT_Development_support_services />} />
            <Route path="/solutions" element={<Solutions />} />
        </Routes>
    );
};

export default WebAppRoutes;